<template>
  <div>
    <VTitle :title="$t('app.lessons', 2)" class="section-title" />

    <VList
      :rows="rows"
      :headers="headers"
      display-pagination
      hide-header
      :is-loading="isLoading"
      :pagination="pagination"
      @click:row="onClickRedirectToLesson"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div>
          <div class="font-medium whitespace-nowrap">
            {{ item.displayed_name }}
          </div>

          <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
            {{ item.title }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import usePaginate from "@/composables/usePaginate";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import VTitle from "@/components/VTitle";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VList from "@/components/tables/VList";

export default {
  components: {
    VTitle,
    VChip,
    VList,
    VImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.users"),
        value: "name"
      },
      {
        text: t("app.username"),
        value: "username"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();

    // Computed
    const rows = computed(() => {
      return data.value?.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_name: `${row.firstname} ${row.lastname}`,
        displayed_status: row.status ? t(`app.${row.status}`) : "",
        displayed_avatar: getAvatarURL(row?.avatar?.view_path)
      }));
    });
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.lessons", 2)} - ${t("app.instructors", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.instructors.lessons",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });
    };

    const onClickRedirectToLesson = row => {
      router.push({
        name: "teaching-lessons-overview",
        params: { id: row?.id }
      });
    };

    // eslint-disable-next-line
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      rows,
      headers,
      onClickRedirectToLesson,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useColor
      getStatusColor
    };
  }
};
</script>
